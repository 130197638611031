import React from "react"
import Layout from "../components/layout"
import Helmet from "react-helmet"
import { Link } from "gatsby"
import "../css/main.css"
import discord from "../../static/Discord-Logo-Black.svg"
import linkedin from "../../static/linkedin-brands.svg"
import arrow from "../../static/angle-right-solid.svg"
import bird from "../../static/cape-may-bird.jpeg"

export default () => (
    <Layout className="h-full w-full">
        <Helmet>
            <title>daniel stewart - home</title>
            <script src="https://kit.fontawesome.com/4d67d05bdb.js" crossorigin="anonymous"></script>
        </Helmet>        
        <div className="flex flex-row justify-around items-end mb-6 md:mb-24">
            <div className="font-black text-xl">daniel stewart</div>
            <div>hello 👋</div>
        </div>
        <div className=" mb-12 lg:mb-24 md:text-center">
            <p className="mb-6">Just taking life as it comes. Trying to get better everyday.</p>
            <p> Also getting older everyday. That's not good.</p>
        </div>
        <div className="flex flex-col lg:flex-row justify-between">
            <div class="flex-none">
                <div className="flex flex-col md:flex-row justify-around mb-6 lg:flex-col">
                    <div>
                        <p className="text-lg">contact</p>
                        <ul className="py-6">
                            <li className="my-2">
                                <img className="w-8 inline mr-2" src={discord} />
                                <a href="#">@kairos#0001</a>
                            </li>                    
                            <li className="my-2">
                                <img className="w-4 inline ml-2 mr-4" src={arrow} />
                                <a href="mailto:contactdasiii@gmail.com">contactdasiii@gmail.com</a>
                            </li>
                            <li className="my-2">
                                <img className="w-6 inline ml-1 mr-3" src={linkedin} />
                                <a href="https://www.linkedin.com/in/dasiii/">linkedin</a>
                            </li>
                        </ul>
                    </div>
                    <div>
                        <p className="text-lg">some links</p>
                        <ul className="py-6">
                            <li className="my-2">
                                <img className="w-4 inline ml-2 mr-4" src={arrow} />
                                <Link to="/about">/about</Link>
                            </li>                    
                            <li className="my-2">
                                <img className="w-4 inline ml-2 mr-4" src={arrow} />
                                <Link to="/images">/images</Link>
                            </li>                   
                        </ul>
                    </div>                        
                </div>
            </div>
            <div class="flex-auto">
                <img className="mb-8 lg:mb-0 lg:w-3/4 lg:mx-auto" src={bird} />
            </div>
        </div>
    </Layout>
)